<template>
  <div class="occupy">
        <van-nav-bar
            title="占用押金"
            left-arrow
            @click-left="onClickLeft"
            safe-area-inset-top 
            fixed
            placeholder
            :border="false"
        >
        </van-nav-bar>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" style="min-height: 100vh;">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div class="cell flex" v-for="item in list" :key="item" :title="item">
                    <div class="leftLine"></div>
                    <div class="rightBox">
                            <div class="top flex_between">
                                <div class="category">黄金10g  <div class="isSell">卖料</div>  </div>
                                <div class="state">待审核</div>
                            </div>
                            <div class="yajin">占用押金：1000元</div>
                            <div class="orderTime flex_between">
                                <div class="danhao van-ellipsis">订单单号：20220605156...</div>
                                <div class="time">2022-06-05 12:34:22</div>
                            </div>
                    </div>
            </div>
          </van-list>
        </van-pull-refresh>
   </div>
</template>

<script>
export default {
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
        }
    },
     methods: {
        onClickLeft(){
                this.$router.push({
                    name:'pricingDeposit'
                })
        },
        onLoad() {
          setTimeout(() => {
            if (this.refreshing) {
              this.list = [];
              this.refreshing = false;
            }

            for (let i = 0; i < 3; i++) {
              this.list.push(this.list.length + 1);
            }
            this.loading = false;

            if (this.list.length >= 2) {
              this.finished = true;
            }
          }, 1000);
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.onLoad();
        },
  },
}
</script>

<style lang="scss" scoped>
    ::v-deep{
        .van-pull-refresh{
            background-color: #f5f5f5;
            padding: 20px 30px;
        }
        .van-loading__text{
            font-size: 30px !important;
        }
        .van-list__finished-text{
            font-size: 30px !important;
        }
        .van-pull-refresh__head{
            font-size: 30px !important;
        }
        .van-nav-bar{
        height: 84px;
        .van-nav-bar__content{
        line-height: 84px;
        height: 84px;
        .van-nav-bar__title{
            font-size: 32px;
        }
        .van-icon{
            font-size: 36px;
            color: #212121;
        }
        }
     }
    }
    .cell{
        width: 100%;
        height: 212px;
        border-radius:8px;
        background: #FFFFFF;
        margin-bottom: 30px;
        .leftLine{
            height: 212px;
            width: 8px;
            background: #CC0E0F;
            border-radius: 8px 0px 0px 8px;
        }
        .rightBox{
            width: 96%;
            padding: 0 0 0 20px;
            .top{
                    padding: 30px 0  0px;
                    font-size: 32px;
                    color: #242424;
                    .category{
                            position: relative;
                            width: 200px;
                        .isSell{
                            position: absolute;
                            padding: 0px 12px;
                            font-size: 20px;
                            top: -10px;
                            right: 0;
                            color: #fff;
                            background-color:#CC0E0F ;
                        }
                    } 
                    .state{
                        font-size: 28px;
                        padding: 6px 20px;
                        border-radius:50px;
                        color: #fff;
                        background-color: #CC0E0F;
                    }
            }
            .yajin{
                font-size: 28px;
                color: #999999;
                padding: 0 0 20px;
                border-bottom: 1px solid #E9E9E9;
            }
            .orderTime{
                padding: 26px 0 0 0 ;
                font-size: 24px;
                color: #999999;
                .danhao{
                    width: 300px;
                }
            }
        }
    }
    .flex{
        display: flex;
    }
    .flex_between{
        display: flex;
        justify-content: space-between;
    }
</style>