var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "occupy" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "占用押金",
          "left-arrow": "",
          "safe-area-inset-top": "",
          fixed: "",
          placeholder: "",
          border: false
        },
        on: { "click-left": _vm.onClickLeft }
      }),
      _c(
        "van-pull-refresh",
        {
          staticStyle: { "min-height": "100vh" },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing"
          }
        },
        [
          _c(
            "van-list",
            {
              attrs: { finished: _vm.finished, "finished-text": "没有更多了" },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function($$v) {
                  _vm.loading = $$v
                },
                expression: "loading"
              }
            },
            _vm._l(_vm.list, function(item) {
              return _c(
                "div",
                { key: item, staticClass: "cell flex", attrs: { title: item } },
                [
                  _c("div", { staticClass: "leftLine" }),
                  _c("div", { staticClass: "rightBox" }, [
                    _c("div", { staticClass: "top flex_between" }, [
                      _c("div", { staticClass: "category" }, [
                        _vm._v("黄金10g "),
                        _c("div", { staticClass: "isSell" }, [_vm._v("卖料")])
                      ]),
                      _c("div", { staticClass: "state" }, [_vm._v("待审核")])
                    ]),
                    _c("div", { staticClass: "yajin" }, [
                      _vm._v("占用押金：1000元")
                    ]),
                    _c("div", { staticClass: "orderTime flex_between" }, [
                      _c("div", { staticClass: "danhao van-ellipsis" }, [
                        _vm._v("订单单号：20220605156...")
                      ]),
                      _c("div", { staticClass: "time" }, [
                        _vm._v("2022-06-05 12:34:22")
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }